import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import Markdown from '../markdown/Markdown';

const Popover = ({ children, content }) => {
  if (!content) {
    return <span ref={childRef}>{children}</span>;
  }
  const [isVisible, setIsVisible] = useState(false);
  const [childWidth, setChildWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const childRef = useRef(null);
  const contnentRef = useRef(null);
  useEffect(() => {
    if (childRef.current) {
      setChildWidth(childRef.current.getBoundingClientRect()?.width);
    }
    if (contnentRef.current) {
      setContentWidth(contnentRef.current.getBoundingClientRect()?.width);
    }
  }, []);
  return (
    <div
      className="position-relative display-inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div
        className={classNames(
          'position-absolute bottom-0 border p-2 m-2 radius-2 bg-white color-black'
        )}
        style={{
          opacity: isVisible ? 1 : 0,
          maxWidth: 600,
          maxHeight: 400,
          //width: contentWidth + childWidth,
          left: childWidth + 8,
          //right: -(contentWidth + childWidth + 20),
          overflowY: 'scroll',
          pointerEvents: 'none'
        }}
      >
        <span ref={contnentRef}>{content}</span>
      </div>
      <span ref={childRef}>{children}</span>
    </div>
  );
};

export default Popover;
