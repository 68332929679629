/**
 * @generated SignedSource<<85483246af5f3d96d336aac83a4065d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EntityType = "ART" | "BOOK" | "EXAM" | "GENERIC" | "LESSON" | "PERSON" | "TECH" | "THEORY" | "%future added value";
export type Status = "PRIVATE" | "PROTECTED" | "PUBLIC" | "%future added value";
export type UpdatePostMutation$variables = {
  body?: string | null;
  darkThemeImageUrl?: string | null;
  description?: string | null;
  entityEndDate?: string | null;
  entityStartDate?: string | null;
  entityType?: EntityType | null;
  imageUrl?: string | null;
  lightThemeImageUrl?: string | null;
  percentage?: number | null;
  permalink?: string | null;
  socialImageUrl?: string | null;
  status?: Status | null;
  title?: string | null;
};
export type UpdatePostMutation$data = {
  readonly updatePost: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  } | null;
};
export type UpdatePostMutation = {
  response: UpdatePostMutation$data;
  variables: UpdatePostMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "darkThemeImageUrl"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityEndDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityStartDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageUrl"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lightThemeImageUrl"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "percentage"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "permalink"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "socialImageUrl"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v13 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "body",
        "variableName": "body"
      },
      {
        "kind": "Variable",
        "name": "darkThemeImageUrl",
        "variableName": "darkThemeImageUrl"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "entityEndDate",
        "variableName": "entityEndDate"
      },
      {
        "kind": "Variable",
        "name": "entityStartDate",
        "variableName": "entityStartDate"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      },
      {
        "kind": "Variable",
        "name": "imageUrl",
        "variableName": "imageUrl"
      },
      {
        "kind": "Variable",
        "name": "lightThemeImageUrl",
        "variableName": "lightThemeImageUrl"
      },
      {
        "kind": "Variable",
        "name": "percentage",
        "variableName": "percentage"
      },
      {
        "kind": "Variable",
        "name": "permalink",
        "variableName": "permalink"
      },
      {
        "kind": "Variable",
        "name": "socialImageUrl",
        "variableName": "socialImageUrl"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "ValidationResponse",
    "kind": "LinkedField",
    "name": "updatePost",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePostMutation",
    "selections": (v13/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v6/*: any*/),
      (v10/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v12/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/),
      (v11/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdatePostMutation",
    "selections": (v13/*: any*/)
  },
  "params": {
    "cacheID": "a3260c341d0b6785de6c6eb2b9a371b7",
    "id": null,
    "metadata": {},
    "name": "UpdatePostMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePostMutation(\n  $permalink: String\n  $imageUrl: String\n  $socialImageUrl: String\n  $darkThemeImageUrl: String\n  $lightThemeImageUrl: String\n  $title: String\n  $description: String\n  $percentage: Int\n  $status: Status\n  $body: String\n  $entityType: EntityType\n  $entityStartDate: String\n  $entityEndDate: String\n) {\n  updatePost(permalink: $permalink, imageUrl: $imageUrl, socialImageUrl: $socialImageUrl, darkThemeImageUrl: $darkThemeImageUrl, lightThemeImageUrl: $lightThemeImageUrl, title: $title, description: $description, percentage: $percentage, status: $status, body: $body, entityType: $entityType, entityStartDate: $entityStartDate, entityEndDate: $entityEndDate) {\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb1da1a727c7011617e69df0d870e71b";

export default node;
