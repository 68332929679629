/**
 * @generated SignedSource<<66077e9f5ee783c734497fb4917e79c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EntityType = "ART" | "BOOK" | "EXAM" | "GENERIC" | "LESSON" | "PERSON" | "TECH" | "THEORY" | "%future added value";
export type Status = "PRIVATE" | "PROTECTED" | "PUBLIC" | "%future added value";
export type PostsByEntityTypeQuery$variables = {
  entityType?: EntityType | null;
};
export type PostsByEntityTypeQuery$data = {
  readonly posts: ReadonlyArray<{
    readonly body: string | null;
    readonly createdAt: string | null;
    readonly darkThemeImageUrl: string | null;
    readonly description: string | null;
    readonly entityEndDate: string | null;
    readonly entityStartDate: string | null;
    readonly entityType: EntityType | null;
    readonly imageUrl: string | null;
    readonly lightThemeImageUrl: string | null;
    readonly modifiedAt: string | null;
    readonly percentage: number | null;
    readonly permalink: string | null;
    readonly socialImageUrl: string | null;
    readonly status: Status | null;
    readonly title: string | null;
  } | null> | null;
};
export type PostsByEntityTypeQuery = {
  response: PostsByEntityTypeQuery$data;
  variables: PostsByEntityTypeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "entityType",
            "variableName": "entityType"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "Post",
    "kind": "LinkedField",
    "name": "posts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permalink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "modifiedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lightThemeImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "darkThemeImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "socialImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityStartDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityEndDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostsByEntityTypeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostsByEntityTypeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac957dbf5387c0aeecd8c99c286f3a70",
    "id": null,
    "metadata": {},
    "name": "PostsByEntityTypeQuery",
    "operationKind": "query",
    "text": "query PostsByEntityTypeQuery(\n  $entityType: EntityType\n) {\n  posts(where: {entityType: $entityType}) {\n    permalink\n    title\n    description\n    createdAt\n    modifiedAt\n    percentage\n    body\n    status\n    imageUrl\n    lightThemeImageUrl\n    darkThemeImageUrl\n    socialImageUrl\n    entityType\n    entityStartDate\n    entityEndDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "9f8c14095b79d01c837200ac7f2701c7";

export default node;
