/**
 * @generated SignedSource<<194062b5013ac7cc1f6cd661303ff901>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EntityType = "ART" | "BOOK" | "EXAM" | "GENERIC" | "LESSON" | "PERSON" | "TECH" | "THEORY" | "%future added value";
export type PrivatePostsQuery$variables = {};
export type PrivatePostsQuery$data = {
  readonly posts: ReadonlyArray<{
    readonly body: string | null;
    readonly createdAt: string | null;
    readonly darkThemeImageUrl: string | null;
    readonly entityEndDate: string | null;
    readonly entityStartDate: string | null;
    readonly entityType: EntityType | null;
    readonly imageUrl: string | null;
    readonly lightThemeImageUrl: string | null;
    readonly percentage: number | null;
    readonly permalink: string | null;
    readonly title: string | null;
  } | null> | null;
};
export type PrivatePostsQuery = {
  response: PrivatePostsQuery$data;
  variables: PrivatePostsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "status": "PRIVATE"
        }
      }
    ],
    "concreteType": "Post",
    "kind": "LinkedField",
    "name": "posts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permalink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "darkThemeImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lightThemeImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityStartDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityEndDate",
        "storageKey": null
      }
    ],
    "storageKey": "posts(where:{\"status\":\"PRIVATE\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivatePostsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrivatePostsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "39afebac39df7caa14ae57f9a36f45c0",
    "id": null,
    "metadata": {},
    "name": "PrivatePostsQuery",
    "operationKind": "query",
    "text": "query PrivatePostsQuery {\n  posts(where: {status: PRIVATE}) {\n    permalink\n    title\n    createdAt\n    percentage\n    body\n    imageUrl\n    darkThemeImageUrl\n    lightThemeImageUrl\n    entityType\n    entityStartDate\n    entityEndDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "8318fd93b1baa03ce5d41db713efdbda";

export default node;
